import DateFilterType from '@/enums/DateFilterType'
import TransportationType from '@/enums/TransportationType'

export type Filter = {
	userId: string | null
	start: string | null
	end: string | null
	startTerminalId: string | null
	endTerminalId: string | null
	certificationClaimIds: string | null
	clientId: string | null
	vesselId: string | null
	product: string | null
	dossierNumber: string | null
	clientOrderReference: string | null
	transportationType: TransportationType
	dateFilterType: DateFilterType
}

export default {
	namespaced: true,
	state: () => ({
		filterUpdated: 0,
		userId: null,
		start: null,
		end: null,
		dossierNumber: null,
		startTerminalId: null,
		endTerminalId: null,
		certificationClaimIds: null,
		vesselId: null,
		product: null,
		clientOrderReference: null,
		clientId: null,
		transportationType: TransportationType.VESSEL,
		dateFilterType: DateFilterType.BASED_ON_TERMS
	}),

	mutations: {
		setFilter(state, filters: Filter) {
			Object.assign(state, filters)
			state.filterUpdated++
		}
	},
	getters: {
		isFiltered: (state) => {
			return (
				[
					state.userId,
					state.start,
					state.end,
					state.startTerminalId,
					state.endTerminalId,
					state.certificationClaimIds,
					state.dossierNumber,
					state.product,
					state.clientOrderReference,
					state.clientId
				].filter(Boolean).length > 0
			)
		}
	},
	actions: {
		clearFilter({ commit }) {
			const nullFilter: Filter = {
				userId: null,
				start: null,
				end: null,
				startTerminalId: null,
				endTerminalId: null,
				certificationClaimIds: null,
				clientId: null,
				vesselId: null,
				product: null,
				clientOrderReference: null,
				dossierNumber: null,
				transportationType: TransportationType.VESSEL,
				dateFilterType: DateFilterType.BASED_ON_TERMS
			}

			commit('setFilter', nullFilter)
		}
	}
}
